import { useEffect, useRef, useState } from 'react';
import * as bulmaToast from "bulma-toast";
import './App.css';
import useAutosizeTextArea from './useAutosizeTextarea';
import { expandOnText, generateMainText, giveHints } from './services/open-ai-services';

const AutoTextArea = (
  props: {
    disabled: boolean,
    value: string,
    index: number,
    placeholder: string,
    onChange: (value: string, index: number) => void,
    onBlur?: () => void,
  }
) => {

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, props.value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value as string;
    props.onChange(val, props.index);
  };

  useEffect(() => {
    props.onChange(props.value, props.index);
  }, []);

  return (
    <textarea
      id='review-text'
      placeholder={props.placeholder}
      className='custom-textarea mb-4'
      disabled={props.disabled}
      onChange={(e) => handleChange(e)}
      onBlur={props.onBlur}
      ref={textAreaRef}
      rows={1}
      value={props.value}>
    </textarea>
  )
}

export enum Type {
  ATTRIBUTE = "attribute-based",
  FREEFORM = "freeform",
  START_STOP_CONTINUE = "start-stop-continue",
  STRENGTH_IMPROVEMENT = "strength-improvement",
};

export enum Language {
  ENGLISH = "en-US",
  SPANISH = "es-ES",
  FRENCH = "fr-FR",
  GERMAN = "de-DE",
  ITALIAN = "it-IT",
  ROMANIAN = "ro-RO",
};

export enum Relationship {
  Myself = 'myself',
  Colleague = 'colleague',
  Manager = 'manager',
  Report = 'report',
};

export enum Performance {
  Below = 'below',
  Above = 'above',
  Meets = 'meets',
};

export enum Time {
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'half-year',
  Year = 'year',
};

export enum Tone {
  Friendly = 'friendly',
  Neutral = 'neutral',
  Critical = 'critical',
};

export enum Pronoun {
  They = 'they',
  He = 'he',
  She = 'she',
};

export type PersonDetails = {
  statement: string;
  name: string;
  role: string;
  team: string;
  perf: Performance;
  time: Time;
  tone: Tone;
  type: Type;
  pronoun: Pronoun;
  details: string;
  relationship: Relationship;
  language: Language;
};

export type Result = {
  original: string;
  expanded: string;
};

const DetailsInput = (props: { role: string | undefined, performance: Performance, tone: Tone, onDetailsUpdated: (details: string) => void }) => {

  const html = `Add more details, such as:
  - a short summary of your performance
  - or a section on things you did well and things to improve
  - or be as succint as listing attributes 'communication: good, leadership: to improve'

Or press the 'Inspiration' button to provide a starting point based on the details you provided above.
  `;

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState('');

  const onChange = (value: string) => setDetails(value);

  const onHintClick = async () => {
    setLoading(true);
    const hint = await giveHints(props.role, props.performance, props.tone);
    setDetails(hint);
    setLoading(false);
  }

  useEffect(() => {
    props.onDetailsUpdated(details);
  }, [details])

  return (
    <>
      <div className='columns mb-4 mt-4 border-middle'></div>
      <div>
        <div className='columns is-vcentered border-middle'>
          <div className='column'>
            <AutoTextArea
              disabled={loading}
              index={999}
              placeholder={html}
              value={details}
              onChange={(val, i) => onChange(val)}
            />
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <button className={'mt-1 button is-text ' + (loading ? 'is-loading' : '')} onClick={() => onHintClick()}>Inspiration</button>
      </div>
    </>
  )
}

const MainInput = (props: { onChanges: (input: PersonDetails) => void }) => {

  const [statement, setStatement] = useState('Please write a performance review with the following details:');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [team, setTeam] = useState('');
  const [perf, setPerformance] = useState<Performance>(Performance.Above);
  const [time, setTime] = useState<Time>(Time.Quarter);
  const [tone, setTone] = useState<Tone>(Tone.Neutral);
  const [pronoun, setPronoun] = useState<Pronoun>(Pronoun.They);
  const [details, setDetails] = useState('');

  useEffect(() => {
    const relationship = name === '' ? Relationship.Myself : Relationship.Colleague;
    const language = Language.ENGLISH;
    const type = Type.FREEFORM;
    const input = {
      statement, name, role, team, perf, time, tone, pronoun, details, relationship, language, type,
    };
    props.onChanges(input);
  }, [statement, name, role, team, perf, time, tone, pronoun, details]);

  const onQuestionChanged = (value: string, index: number) => setStatement(value);

  return (
    <div className='main-border p-3'>
      <div>
        <label className='custom-label mr-1'>Name</label>
        <input className='custom-input' type={'text'} placeholder='Myself' value={name} onChange={(e) => setName(e.target.value)}></input>
      </div>
      <div>
        <label className='custom-label mr-1'>Role</label>
        <input className='custom-input' type={'text'} placeholder='(optional)' value={role} onChange={(e) => setRole(e.target.value)}></input>
      </div>
      <div>
        <label className='custom-label mr-1'>Team</label>
        <input className='custom-input' type={'text'} placeholder='(optional)' value={team} onChange={(e) => setTeam(e.target.value)}></input>
      </div>
      <div>
        <label className='custom-label mr-1'>Perf</label>
        <select className='custom-select' value={perf} onChange={(e) => setPerformance(e.currentTarget.value as Performance)}>
          <option value={Performance.Below}>Below expectation</option>
          <option value={Performance.Meets}>Meets expectation</option>
          <option value={Performance.Above}>Above expectation</option>
        </select>
      </div>
      <div>
        <label className='custom-label mr-1'>Time</label>
        <select className='custom-select' value={time} onChange={(e) => setTime(e.currentTarget.value as Time)}>
          <option value={Time.Month}>Previous month</option>
          <option value={Time.Quarter}>Previous 3 months</option>
          <option value={Time.HalfYear}>Previous 6 months</option>
          <option value={Time.Year}>Previous year</option>
        </select>
      </div>
      <div>
        <label className='custom-label mr-1'>Tone</label>
        <select className='custom-select' value={tone} onChange={(e) => setTone(e.currentTarget.value as Tone)}>
          <option value={Tone.Neutral}>Neutral</option>
          <option value={Tone.Friendly}>Friendly</option>
          <option value={Tone.Critical}>Critical</option>
        </select>
      </div>
      <div>
        <label className='custom-label mr-1'>Pronoun</label>
        <select className='custom-select' value={pronoun} onChange={(e) => setPronoun(e.currentTarget.value as Pronoun)}>
          <option value={Pronoun.They}>They</option>
          <option value={Pronoun.He}>He/Him</option>
          <option value={Pronoun.She}>She/Her</option>
        </select>
      </div>
      <div>
      <div className='columns mb-4 mt-4 border-middle'></div>
        <AutoTextArea disabled={false} value={statement} index={999} placeholder='Write your question here ...' onChange={onQuestionChanged} />
      </div>
      <div>
        <DetailsInput role={role} performance={perf} tone={tone} onDetailsUpdated={(d) => setDetails(d)} />
      </div>
    </div>
  )
}

const formResult = (initial: string[]): Result[] => initial.map(original => ({ original, expanded: original } ));

const App = () => {

  const [results, setResults] = useState<Result[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [reloadingSection, setReloadingSection] = useState<number | undefined>(undefined);
  const [input, setInput] = useState<PersonDetails | undefined>(undefined);

  const onCopyToClipboard = async () => {
    const answer = results.map(e => e.expanded).join('\n\n');
    await navigator.clipboard.writeText(answer);
    bulmaToast.toast({
      message: "Copied to clipboard",
      type: "is-info",
      position: "bottom-center",
      closeOnClick: true,
      duration: 1000,
      animate: { in: "fadeIn", out: "fadeOut" },
    });
  }

  const onGenerateClick = async () => {
    setLoading(true);
    const result = await generateMainText(input);
    setResults(formResult(result));
    setLoading(false);
  }

  const onExpandClick = async (result: Result, index: number) => {
    setReloadingSection(index);
    const expanded = await expandOnText(result.expanded);
    const newResults: Result[] = results.map((res, i) => {
      if (i === index) {
        return { original: res.original, expanded };
      } else {
        return res;
      }
    });
    setResults(newResults);
    setReloadingSection(undefined);
  }

  const onChange = (newString: string, index: number) => {
    const newResults = results.map((res, i) => {
      if (i === index) {
        return { original: res.original, expanded: newString };
      } else {
        return res;
      }
    })
    setResults(newResults);
  }

  const deleteElement = (index: number) => {
    const newResults = results.filter((e, i) => i !== index);
    setResults(newResults);
  }

  const addElementAfter = (index: number) => {
    const newResults = results.flatMap((e, i) => {
      if (i === index) {
        return [e, { original: '', expanded: '' }];
      } else {
        return [e];
      }
    })
    setResults(newResults);
  }

  const onBlur = (index: number) => {
    const newResults = results.filter((e, i) => {
      if (i === index) {
        return !(e.expanded === '');
      } else {
        return true;
      }
    });
    setResults(newResults);
  }

  const onReset = (index: number) => {
    const newResults = results.map((e, i) => {
      if (i === index) {
        return { original: e.original, expanded: e.original };
      } else {
        return e;
      }
    });
    setResults(newResults);
  }

  return (
    <div className='container'>
      <div className='content m-6'>
        <MainInput onChanges={setInput} />

        <div className='mt-4'>
          {results.map((res, i) => (
            <div key={i} >
              <div className={i === 0 ? 'top-content' : (i === results.length - 1 ? 'bottom-content' : 'normal-content')}>
                <div className='columns m-0 p-0 is-vcentered'>
                  <div className='column is-narrow m-0 p-0 ml-2'>
                    <button title='Expand on this section' disabled={!!reloadingSection || isLoading} className='button is-white is-small' onClick={() => onExpandClick(res, i)}>
                      <span className="icon is-small">
                        <i className="fas fa-sync"></i>
                      </span>
                    </button>
                  </div>
                  <div className='column m-4 p-0'>
                    <AutoTextArea 
                      placeholder='Add more details' 
                      disabled={reloadingSection === i || isLoading} 
                      value={res.expanded} index={i} 
                      onChange={onChange}
                      onBlur={() => onBlur(i)} />
                  </div>
                  <div className='column is-narrow m-0 p-0 mr-2'>
                    <button title='Remove section' disabled={!!reloadingSection || isLoading} className='button is-small is-white' onClick={() => onReset(i)}>
                      <span className="icon is-small">
                        <i className="fas fa-history"></i>
                      </span>
                    </button>
                  </div>
                </div>

              </div>
              <div className='has-text-centered button-holder'>
                <button title='Add new section' disabled={!!reloadingSection || isLoading} className='button is-small is-rounded plus-button' onClick={() => addElementAfter(i)}>
                  <span className="icon is-small has-text-success">
                    <i className="fas fa-plus"></i>
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className='mt-4'>
          <button disabled={isLoading} className='button is-primary' onClick={onGenerateClick}>Generate</button>
          <button className='button ml-2' onClick={onCopyToClipboard}>Copy to clipboard</button>
        </div>
      </div>
    </div>
  );
}

export default App;
