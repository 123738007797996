import { PersonDetails, Pronoun, Performance, Tone, Time } from "../App";

export class FlexiblePromptBuilder {
  build(details: PersonDetails): string {
    let prompt: string[] = [];

    prompt.push(details.statement);
    if (details.details) {
      prompt.push(details.details);
    }
    prompt.push(`Please also add the following personal details`);
    if (details.name) {
      prompt.push(`Person Name: ${details.name}`);
      switch (details.pronoun) {
        case Pronoun.They: {
          prompt.push(`Use the pronoun 'they'`);
          break;
        }
        case Pronoun.He: {
          prompt.push(`Use the pronoun 'he'`);
          break;
        }
        case Pronoun.She: {
          prompt.push(`Use the pronoun 'she'`);
          break;
        }
      }
    } else {
      prompt.push(`Person: Myself`);
      prompt.push(`Refer to me in the first person`);
    }
    if (details.role) {
      prompt.push(`Role: ${details.role}`);
    }
    if (details.team) {
      prompt.push(`Team: ${details.team}`);
    }
    switch (details.perf) {
      case Performance.Above: {
        prompt.push(`Performance: above expectations`);
        break;
      }
      case Performance.Meets: {
        prompt.push(`Performance: meets expectations`);
        break;
      }
      case Performance.Below: {
        prompt.push(`Performance: below expectations`);
        break;
      }
    }
    switch (details.time) {
      case Time.Month: {
        prompt.push(`Time period: previous month`);
        break;
      }
      case Time.Quarter: {
        prompt.push(`Time period: previous quarter`);
        break;
      }
      case Time.HalfYear: {
        prompt.push(`Time period: previous half year`);
        break;
      }
      case Time.Year: {
        prompt.push(`Time period: previous year`);
        break;
      }
    }
    switch (details.tone) {
      case Tone.Neutral: {
        prompt.push(`Use a neutral tone`);
        break;
      }
      case Tone.Friendly: {
        prompt.push(`Use a friendly tone`);
        break;
      }
      case Tone.Critical: {
        prompt.push(`Use a critical tone`);
        break;
      }
    }

    return prompt.join('\n');
  }
}

export class InspirationPromptBuilder {
  build(role: string | undefined, performance: Performance, tone: Tone): string {
    let good = 0;
    let bad = 0;

    switch (performance) {
      case Performance.Above: {
        good += 2;
        bad += 1;
        break;
      }
      case Performance.Meets: {
        good += 1;
        bad += 1;
        break;
      }
      case Performance.Below: {
        good += 1;
        bad += 2;
        break;
      }
    }

    switch (tone) {
      case Tone.Friendly: {
        good += 1;
        break;
      }
      case Tone.Neutral: {
        good += 1;
        bad += 1;
        break;
      }
      case Tone.Critical: {
        bad += 1;
        break;
      }
    }

    let rolePart = 'an employee';
    if (role) {
      role += `a ${role}`;
    }

    let perfPart = '';
    switch (performance) {
      case Performance.Above: {
        perfPart = 'has performed above expectations';
        break;
      }
      case Performance.Meets: {
        perfPart = 'meets expectations';
        break;
      }
      case Performance.Below: {
        perfPart = 'has performed below expectations';
        break;
      }
    }

    return `Please list ${good} strengths and ${bad} areas of improvement for a ${rolePart} who ${perfPart}.`;
  }
}